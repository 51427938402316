<template>
  <div class="d-flex justify-space-between align-center pa-1">
    <div>
      <v-btn
        v-if="step > 1 && !hideBackButton"
        outlined
        color="secondary"
        @click="previousStep"
      >
        <v-icon class="mr-2">arrow_back</v-icon>
        Voltar
      </v-btn>
      <v-btn
        v-if="step === 4 && hideBackButton"
        outlined
        color="secondary"
        @click="cancelAppointment"
      >
        <v-icon class="mr-2">close</v-icon>
        Cancelar
      </v-btn>
    </div>
    <div>
      <v-btn
        v-if="step <= 3"
        :disabled="validateNextRules"
        color="secondary"
        @click="nextStep"
      >
        Próximo
        <v-icon class="ml-2">arrow_forward</v-icon>
      </v-btn>
      <v-btn v-if="step === 4" color="secondary" @click="finish">
        Enviar
        <v-icon class="ml-2">check</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  name: "StepperButtons",
  props: {
    hideBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("appointments/patient", ["step"]),
    ...mapGetters("appointments/patient", [
      "hasTherapist",
      "hasDate",
      "hasTime",
    ]),
    validateNextRules() {
      const mapConditions = new Map([
        [1, this.hasTherapist],
        [2, this.hasDate],
        [3, this.hasTime],
      ]);
      return !mapConditions.get(this.step);
    },
  },
  methods: {
    ...mapMutations("appointments/patient", ["previousStep", "nextStep"]),
    async finish() {
      try {
        await this.$store.dispatch(
          "appointments/patient/isPatientAbleToAppoint"
        );
        this.$store.dispatch("appointments/patient/finish");
        localStorage.removeItem("appointmentDetails");
        this.$toasted.global.success({
          message: "Agendamento realizado com sucesso.",
        });
        this.$router.push({ name: "listAppointmentsPatient" });
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    cancelAppointment() {
      localStorage.removeItem("appointmentDetails");
      this.$router.push({ path: "/home" });
    },
  },
};
</script>

<style scoped></style>
